'use client'
import React from "react";
import Header from "@/components/common/header";
import Footer from "@/components/common/footer";
import ErrorPage from "@/components/error-page/error-main";

export const generateMetadata = async ({ params }) => {
    const {metadataStatic} = await getDictionary(params.lang)
    // console.log(metadataStatic);
    return {
        title: metadataStatic.errorpageMetadata.errorpageTitle,
        description: metadataStatic.errorpageMetadata.errorpageDescription,
        keywords: metadataStatic.errorpageMetadata.errorpageKeywords,
        openGraph: {
            title: metadataStatic.errorpageMetadata.errorpageTitle,
            description: metadataStatic.errorpageMetadata.errorpageDescription,
            // url: `${process.env.NEXT_PUBLIC_FE_URL}/about-us/`,
        },
        // alternates: {
        //     canonical: `${process.env.NEXT_PUBLIC_FE_URL}/about-us/`,
        // }
    }
}

 
export default function GlobalError({ error, reset }) {
    return (
        <html>
            <body>
                <Header className="z-50 w-full block bg-primaryWhite" />
                <ErrorPage/>
                <Footer />
            </body>
        </html>
    )
};
