import Image from 'next/image';
import Link from "next/link";
import errorpg from '@/public/errorpage/error-img.png'


function ErrorPage (){
    return(
    <div className="custom-container h-[70vh] flex items-center justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full">
            <div className="relative flex flex-col lg:items-start items-center justify-between">
                <div className="relative">
                    <h1 className="lg:text-9xl text-8xl  font-bold text-primaryRed capitalize">Error</h1>
                    <Image className="block lg:hidden relative box-border w-full h-auto max-w-xl max-h-96 object-cover object-center md:mx-auto rounded-3xl" src={errorpg} alt="apex-hospitals-errorpage" title="apex-hospitals-errorpage" width={577} height={251} />
                    <p className="md:text-2xl text-xl text-primaryGreyText">Oops! the page you’re looking for does not exist.</p>
                </div>
                <div className="flex flex-nowrap items-center justify-center md:justify-between gap-5 mt-12 lg:mt-0">
                    <Link href='/' className="flex items-center justify-center py-2 w-fit whitespace-pre md:px-5 px-4 sm:w-full border border-primaryBlue bg-primaryBlue text-primaryWhite hover:bg-primaryWhite hover:text-primaryBlue hover:border-primaryblue transition-all duration-700 rounded-3xl text-xs lg:text-lg uppercase">Go to HomePage</Link>
                    <Link href='/contact-us' className="flex items-center justify-center py-2 w-fit whitespace-pre md:px-8 px-4 sm:w-full border bg-primaryWhite border-primaryBlue text-primaryBlue hover:bg-primaryBlue hover:text-primaryWhite hover:border-primaryBlue transition-all duration-700 rounded-3xl text-xs lg:text-lg uppercase">Contact us</Link>
                    
                </div>
            </div>
            <Image className="hidden lg:block relative box-border w-full h-auto max-w-xl max-h-96 object-cover object-center md:mx-auto rounded-3xl" src={errorpg} alt="apex-hospitals-errorpage" title="apex-hospitals-errorpage" width={577} height={251} />
        </div>
    </div>
    )
};

export default ErrorPage;